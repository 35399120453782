import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Homepage/Footer/Footer";
import collage from "../../assets/images/collage.png";
// import afisAndRukky from "../../assets/images/afis-and-rukky.png";
import afisAndRukky from "../../assets/images/arr.jpg";
import advancioAward from "../../assets/images/advansio.svg";
import getEquityAward from "../../assets/images/equity.svg";
import oasisAward from "../../assets/images/oasis.svg";
import CEO from "../../assets/images/theceo.svg";
import COO from "../../assets/images/ceo.svg";
import CTO from "../../assets/images/moses.svg";
import DCTO from "../../assets/images/henry.svg";
import armPensionLogo from "../../assets/images/arm-logo.png";
import getEquity from "../../assets/images/get-equity.png";
import midlothian from "../../assets/images/midlothian.png";
import unsungHeros from "../../assets/images/UNSUNG-Heroes.png";
import villageCapital from "../../assets/images/village-capital.png";
import { BlueLinkedin } from "../../assets/SvgPack";
import PartnersMarquee from "./PartnersMarquee";
import VisionComponent from "./VisionComponent";

function AboutUs() {
  return (
    <main>
      <Navbar />
      <div className="about-us-container">
        <VisionComponent />
        <div className="short-story">
          <div className="afis-and-rukky-image">
            <img src={afisAndRukky} alt="afis-and-rukky" />
          </div>
          <div className="para-right">
            <h1 className="para-right-header">A Short Story</h1>
            <p className="para-right-text">
              Inspired by her mother’s struggles as a micro-business owner,
              Rukayat launched The Bells Dynamic Option to support entrepreneurs
              like her.
              <br />
              <br />
              In 2021, this vision evolved into Regxta , a platform offering
              accessible financial solutions, including digital wallets,
              savings, payments, micro-insurance, pensions, health services, and
              mortgages. Beyond financing, we help businesses thrive with tools
              for restructuring and record-keeping.
            </p>
            <div className="quote-container">
              <div className="quote-icon">“</div>
              <p className="quote-text">
                Economic growth, poverty reduction, and job creation start with
                financial inclusion.
                <strong>
                  Women remain central to our mission because of their
                  dedication to family and community development.
                </strong>
              </p>
              <p className="quote-author">- Rukayat Kolawole</p>
            </div>
          </div>
        </div>
        <div className="awards-and-leadership">
          <h1 className="awards-header">Our Awards</h1>
          <p className="awards-text">
            While we’re just getting started, our commitment to innovation and
            inclusion continues to <br /> create a lasting impact.
          </p>
          <div className="awards">
            <img src={advancioAward} alt="advancio-award" />
            <img src={getEquityAward} alt="get-equity-award" />
            <img src={oasisAward} alt="oasis-award" />
          </div>

          <div className="leadership">
            <h1 className="leadership-header">Meet Our Leadership Team</h1>
            <p className="leadership-text">
              Led by passionate innovators with over 25 years of combined
              experience in banking and fintech, our team specializes in <br />
              business development, IT, risk management, and customer
              experience. Together, we design tailored financial solutions{" "}
              <br /> to reduce poverty and drive economic growth.
            </p>
            <div className="image-container">
              <div className="single-image-container">
                <img src={CEO} alt="ceo-image" className="leader-image" />
                <div className="leader-description">
                  <div className="flex-start">
                    <h1 className="leader-name">Rukayat Kolawole</h1>
                    <p className="leader-title">CEO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/rukayat-kolawole-07ab78156/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={CTO} alt="cto-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Moses Obika</h1>
                    <p className="leader-title">CTO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/moses-obika-b43978156/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={COO} alt="coo-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Afis Bello</h1>
                    <p className="leader-title">COO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/afis-bello-b070b4124/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={DCTO} alt="dcto-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Henry Ozoani</h1>
                    <p className="leader-title">DCTO</p>
                  </div>

                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/ozoani-chibuike-henry/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="investors">
            <h1 className="investors-header">Our Investors</h1>
            <p className="investors-text">
              Our investors share our vision of financial inclusion and are
              committed to empowering Africa’s underserved communities through{" "}
              <br />
              impactful, scalable solutions.
            </p>
          </div>
        </div>
        <PartnersMarquee />
      </div>
      <Footer />
    </main>
  );
}

export default AboutUs;
