import Marquee from "react-fast-marquee";
import getEquity from "../../assets/images/get-equity.png";
import midlothian from "../../assets/images/midlothian.png";
import unsungHeros from "../../assets/images/UNSUNG-Heroes.png";
import villageCapital from "../../assets/images/village-capital.png";
import armPensionLogo from "../../assets/images/arm-logo.png";

const partnersList = [
  {
    img: armPensionLogo,
    id: 4,
  },
  {
    img: getEquity,
    id: 1,
  },
  {
    img: midlothian,
    id: 2,
  },
  {
    img: unsungHeros,
    id: 3,
  },
  {
    img: villageCapital,
    id: 4,
  },
  {
    img: armPensionLogo,
    id: 5,
  },
  {
    img: getEquity,
    id: 6,
  },
  {
    img: midlothian,
    id: 7,
  },
  {
    img: unsungHeros,
    id: 8,
  },
  {
    img: villageCapital,
    id: 9,
  },
];

const PartnersMarquee = () => {
  return (
    <div className="pd_press_section">
      <Marquee
        className="home-marq"
        speed={50}
        pauseOnHover={true}
        pauseOnClick={true}
      >
        {partnersList.map((item, i) => (
          <div key={i} className="item_wrapper">
            <img src={item.img} />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default PartnersMarquee;
