import React from "react";
import "./style.scss";
import collage from "../../assets/images/groupabt.svg";

const VisionComponent = () => {
  return (
    <div className="vision-container">
      <div className="vision-content">
        <h2 className="vision-title">Our Vision</h2>
        <h3 className="vision-header">
          Empowering Africa’s underserved communities by becoming the world's
          most essential bank for unbanked markets.
        </h3>
        <p className="vision-description">
          Regxta is a transformative digital platform bridging the financial gap
          for Africa's unbanked and underserved. We make financial services
          fast, simple, and accessible for micro-businesses, refugees, and
          displaced individuals in rural and peri-urban communities.
        </p>
        <p className="vision-description">
          Using innovative technology, we connect these communities to the
          formal financial sector, helping them build credit histories,
          sustainable business models, and a path to financial freedom.
        </p>
      </div>
      <div className="vision-stats">
        <div className="vision-images">
          <img src={collage} alt="Presentation" className="image-left" />
        </div>
        <div className="stats-grid">
          <div className="stat-box yellow">
            <h4>300+</h4>
            <p>Agents Onboarded</p>
          </div>
          <div className="stat-box sea-green">
            <h4>15,000+</h4>
            <p>Customers</p>
          </div>
          <div className="stat-box purple">
            <h4>$3.5 million+</h4>
            <p>Loans Processed</p>
          </div>
          <div className="stat-box green">
            <h4>4</h4>
            <p>States</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionComponent;
